import React from 'react';
import { Route, Routes} from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './main/component/HeaderFooter/Footer';
import HomePage from './main/component/Pages/HomePage';
import Faq from './main/component/Pages/Faq';
import Registration from './main/component/Pages/Registration';
import ContactUs from './main/component/Pages/ContactUs';
import Header from './main/component/HeaderFooter/Header';

function App() {
  return (
    <div className="App">
      <Header/>
      <main>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/faq' element={<Faq />} />
        <Route path='/registration' element={<Registration />} />
        <Route path='/contact-us' element={<ContactUs />} />
      </Routes>
      </main>
      <Footer />

    </div>
  );
}

export default App;
