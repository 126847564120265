
let Registration = () => {

    return (
        <>
            <div className="registration-container">
                {/* <h4 className="mb-1">* Important* </h4> 
                <h5 className="mb-1">Event is <span className="important-txt">CASH ONLY</span></h5>
                <h5 className="mb-1">There will be <span className="important-txt">No ATM</span> at the venue</h5>
                <h5 className="mb-1">Please bring cash for <span className="important-txt">BONUS CHIPS</span> and <span className="important-txt">RAFFLE TICKETS</span></h5>
                <h5 className="mb-1"><span className="important-txt">NO REFUNDS</span> all donations are final</h5> */}
               
                {/* <h5 className="mb-1 registerText">  Zeffy provides <span className="important-txt">FREE</span> credit card processing to non-profit groups. Supporting the Zeffy platform is <span className="important-txt">OPTIONAL</span>. We <span className="important-txt">DO NOT</span> see if you contributed to Zeffy.</h5>
                <h5 className="mb-1 registerText" ><span className="important-txt">*Note*</span> If you <span className="important-txt">DO NOT</span> want to contribute to Zeffy:</h5>
                <h5 className="mb-1 registerText">1. In the Order Summary section, right of Support the 100% free platform we use! select <span className="important-txt">OTHER</span> in the dropdown box.</h5>
                <h5 className="mb-3 registerText">2. In the Contribution box, type the number <span className="important-txt">0 (zero).</span></h5>
                
                <h5 className="mb-1 registerText"><span className="important-txt bold">Please note: All donations are final and greatly appreciated.</span></h5> */}

                <h4 className="mb-1 registerText text-center">
                    
                    <span className="important-txt">** The April 21 Qualifier Tournament is FULL and online registration is CLOSED. ** </span> 
                    <br></br><br></br>
                    WE INVITE YOU TO COME TO THE TOURNAMENT ON 
                    SUNDAY, APRIL 21 TO REGISTER AND PLAY OUR LAST 
                    QUALIFIER.  
                    <br></br><br></br>
                    Every tournament has people that register and do not attend the tournament for various reasons (no-shows). 
                    This has been the case for all our Qualifiers to date.  
                    <br></br><br></br>
                    We have been able to get <span className="important-txt">EVERYONE</span> that showed up in person to register in our <span className="important-txt">SOLD-OUT Qualifiers,</span> a seat 
                    into our tournaments. We are <span className="important-txt">CONFIDENT</span> we can do this again on April 21st. 
                    <br></br><br></br>
                    **<span className="important-txt"> Santisi Brother</span> announced that it is contributing <span className="important-txt">10% of their sales</span> from the 3 Qualifier Tournaments 
                    to be <span className="important-txt">awarded as a prize pool</span> for those that make the final table and DO NOT win one of the 3 seats to 
                    the <span className="important-txt">ARIZONA STATE POKER CHAMPIONSHIP.</span>**
                </h4>

                <div className="zeffyThings1">
                    <iframe title='Donation form powered by Zeffy' className="zeffyThings2" src='https://www.zeffy.com/en-US/embed/ticketing/f64ae4c2-0fd9-4d1d-a1d1-9c81138bfd27' allowpaymentrequest allowTransparency="true">
                    </iframe>
                </div>

            </div>
        </>
    );

}

export default Registration;