import React from 'react';

let Footer = () => {
    return (

        <footer >
            <div >
                <p className=' text-white'>
                    Copyright &copy; 2023 Autism Resource Foundation – All Rights Reserved.<br />
                    A 501c3 charitable organization formed in 2007 <br />
                    EIN: 26-0635103
                </p>
            </div>
        </footer>

    );
}
export default Footer;